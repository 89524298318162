import React, {Component} from "react";
import {faHome} from "@fortawesome/free-solid-svg-icons";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import {faPuzzlePiece} from "@fortawesome/free-solid-svg-icons";
import {faRobot} from "@fortawesome/free-solid-svg-icons";
import {faThumbsUp} from "@fortawesome/free-solid-svg-icons";
import {faFileExport} from "@fortawesome/free-solid-svg-icons";
import {faCode} from "@fortawesome/free-solid-svg-icons";
import {faDesktop} from "@fortawesome/free-solid-svg-icons";
import {faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";
import {faStickyNote} from "@fortawesome/free-solid-svg-icons";
import {faUsers} from "@fortawesome/free-solid-svg-icons";
import {faCertificate} from "@fortawesome/free-solid-svg-icons";
import {faDollarSign} from "@fortawesome/free-solid-svg-icons";
import {faBriefcase} from "@fortawesome/free-solid-svg-icons";
import {faFile} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class Button extends Component {
    render() {
        return (
            this.props.iconName === "Home" ?
                <FontAwesomeIcon icon={faHome} size={this.props.size || 'lg'}/>
            : this.props.iconName === "Info" ?
                <FontAwesomeIcon icon={faInfoCircle} size={this.props.size || 'lg'}/>
            : this.props.iconName === "Pipeline" ?
                <FontAwesomeIcon icon={faPuzzlePiece} size={this.props.size || 'lg'}/>
            : this.props.iconName === "Cron" ?
                <FontAwesomeIcon icon={faRobot} size={this.props.size || 'lg'}/>
            : this.props.iconName === "Success" ?
                <FontAwesomeIcon icon={faThumbsUp} size={this.props.size || 'lg'}/>
            : this.props.iconName === "Delivery" ?
                <FontAwesomeIcon icon={faFileExport} size={this.props.size || 'lg'}/>
            : this.props.iconName === "Git" ?
                <FontAwesomeIcon icon={faCode} size={this.props.size || 'lg'}/>
            :this.props.iconName === "Demo" ?
                <FontAwesomeIcon icon={faDesktop} size={this.props.size || 'lg'}/>
            :this.props.iconName === "Limit" ?
                <FontAwesomeIcon icon={faExclamationTriangle} size={this.props.size || 'lg'}/>
            :this.props.iconName === "Note" ?
                <FontAwesomeIcon icon={faStickyNote} size={this.props.size || 'lg'}/>
            :this.props.iconName === "Developers" ?
                <FontAwesomeIcon icon={faUsers} size={this.props.size || 'lg'}/>
            :this.props.iconName === "License" ?
                <FontAwesomeIcon icon={faCertificate} size={this.props.size || 'lg'}/>
            :this.props.iconName === "Funding" ?
                <FontAwesomeIcon icon={faDollarSign} size={this.props.size || 'lg'}/>
            :this.props.iconName === "Workshops" ?
                <FontAwesomeIcon icon={faBriefcase} size={this.props.size || 'lg'}/>
            :this.props.iconName === "Publications" ?
                <FontAwesomeIcon icon={faFile} size={this.props.size || 'lg'}/>
            : null
        )
    }
}

export default Button;