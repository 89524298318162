export const fieldNames = [
    { label: "PROCESSED REFERENCES",
        value: [
            { value: "cermine_references_from_cermine_xml", label: "Parsed references from Cermine xml" },
            { value: "cermine_references_from_cermine_refstrings", label: "Parsed references via Cermine using Cermine refstrings" },
            { value: "cermine_references_from_grobid_refstrings", label: "Parsed references via Cermine using Grobid refstrings" },
            { value: "grobid_references_from_grobid_xml", label: "Parsed references from Grobid xml" },
            { value: "anystyle_references_from_cermine_fulltext", label: "Parsed references via Anystyle using Cermine fulltext" },
            { value: "anystyle_references_from_pdftotext_fulltext", label: "Parsed references via Anystyle using Pdftotext fulltext" },
            { value: "anystyle_references_from_cermine_refstrings", label: "Parsed references via Anystyle using Cermine refstrings" },
            { value: "anystyle_references_from_grobid_refstrings", label: "Parsed references via Anystyle using Grobid refstrings" },
            { value: "exparser_references_from_cermine_layout", label: "Parsed references via Exparser using Cermine layout" },
        ]
    },
    { label: "PROCESSED METADATA",
        value: [
            { value: "fulltext", label: "Grobid_fulltext" },
            { value: "cermine_fulltext", label: "Cermine_fulltext" },
            { value: "pdftotext_fulltext", label: "Pdftotext_fulltext" },
            { value: "xml", label: "Grobid_xml" },
            { value: "cermine_xml", label: "Cermine_xml" },
            { value: "cermine_layout", label: "Cermine_layout" },
        ]
    },
    { label: "SOURCE MATCHING",
        value: [
            { value: "sowiport_ids", label: "Sowiport_ids" },
            { value: "crossref_ids", label: "Crossref_ids" },
            { value: "dnb_ids", label: "Dnb_ids" },
            { value: "openalex_ids", label: "Openalex_ids" },
            { value: "ssoar_ids", label: "Ssoar_ids" },
            { value: "arxiv_ids", label: "Arxiv_ids" },
            { value: "gesis_bib_ids", label: "Gesis_bib_ids" },
            { value: "research_data_ids", label: "Research_data_ids" },
            { value: "econbiz_ids", label: "Econbiz_ids" },
            { value: "sowiport_urls", label: "Sowiport_urls" },
            { value: "crossref_urls", label: "Crossref_urls" },
            { value: "dnb_urls", label: "Dnb_urls" },
            { value: "openalex_urls", label: "Openalex_urls" },
            { value: "bing_urls", label: "Bing_urls" },
            { value: "ssoar_urls", label: "Ssoar_urls" },
            { value: "arxiv_urls", label: "Arxiv_urls" },
            { value: "gesis_bib_urls", label: "Gesis_bib_urls" },
            { value: "research_data_urls", label: "Research_data_urls" },
            { value: "econbiz_urls", label: "Econbiz_urls" },
            { value: "ssoar_general_urls", label: "Ssoar_general_urls" },
            { value: "arxiv_general_urls", label: "Arxiv_general_urls" },
            { value: "research_data_general_urls", label: "Research_data_general_urls" },
            { value: "openalex_general_urls", label: "Openalex_general_urls" },
            { value: "econbiz_general_urls", label: "Econbiz_general_urls" },
            { value: "crossref_general_urls", label: "Crossref_general_urls" },
            { value: "extracted_general_urls", label: "Extracted_general_urls" },
            { value: "ssoar_core_fulltext_urls", label: "Ssoar_core_fulltext_urls" },
            { value: "arxiv_core_fulltext_urls", label: "Arxiv_core_fulltext_urls" },
            { value: "research_data_core_fulltext_urls", label: "Research_data_core_fulltext_urls" },
            { value: "openalex_core_fulltext_urls", label: "Openalex_core_fulltext_urls" },
            { value: "econbiz_core_fulltext_urls", label: "Econbiz_core_fulltext_urls" },
            { value: "crossref_core_fulltext_urls", label: "Crossref_core_fulltext_urls" },
            { value: "extracted_core_fulltext_urls", label: "Extracted_core_fulltext_urls" },
            { value: "ssoar_unpaywall_fulltext_urls", label: "Ssoar_unpaywall_fulltext_urls" },
            { value: "arxiv_unpaywall_fulltext_urls", label: "Arxiv_unpaywall_fulltext_urls" },
            { value: "research_data_unpaywall_fulltext_urls", label: "Research_data_unpaywall_fulltext_urls" },
            { value: "openalex_unpaywall_fulltext_urls", label: "Openalex_unpaywall_fulltext_urls" },
            { value: "econbiz_unpaywall_fulltext_urls", label: "Econbiz_unpaywall_fulltext_urls" },
            { value: "crossref_unpaywall_fulltext_urls", label: "Crossref_unpaywall_fulltext_urls" },
            { value: "extracted_unpaywall_fulltext_urls", label: "Extracted_unpaywall_fulltext_urls" },
        ]
    },
    { label: "DETECTED DUPLICATES",
        value: [
            { value: "block_ids", label: "Block_ids" },
            { value: "cluster_ids", label: "Cluster_ids" },
            { value: "duplicate_ids", label: "Duplicate_ids" },
        ]
    },
    { label: "DETECTED MATCHES",
        value: [
            { value: "matched_references_from_sowiport", label: "Matched_references_from_sowiport"},
            { value: "matched_references_from_dnb", label: "Matched_references_from_dnb"},
            { value: "matched_references_from_crossref", label: "Matched_references_from_crossref"},
            { value: "matched_references_from_openalex", label: "Matched_references_from_openalex"},
            { value: "matched_references_from_ssoar", label: "Matched_references_from_ssoar"},
            { value: "matched_references_from_arxiv", label: "Matched_references_from_arxiv"},
            { value: "matched_references_from_gesis_bib", label: "Matched_references_from_gesis_bib"},
            { value: "matched_references_from_research_data", label: "Matched_references_from_research_data"},
            { value: "matched_references_from_econbiz", label: "Matched_references_from_econbiz"},
        ]
    },
    { value: "title", label: "Title" },
    { value: "authors", label: "Authors" },
    { value: "pdf", label: "Pdf" },
    { value: "fulltext", label: "Grobid_fulltext" },
    { value: "cermine_fulltext", label: "Cermine_fulltext" },
    { value: "pdftotext_fulltext", label: "Pdftotext_fulltext" },
    { value: "xml", label: "Grobid_xml" },
    { value: "cermine_xml", label: "Cermine_xml" },
    { value: "cermine_layout", label: "Cermine_layout" },
    { value: "cermine_references_from_cermine_xml", label: "Parsed references from Cermine xml" },
    { value: "cermine_references_from_cermine_refstrings", label: "Parsed references via Cermine using Cermine refstrings" },
    { value: "cermine_references_from_grobid_refstrings", label: "Parsed references via Cermine using Grobid refstrings" },
    { value: "grobid_references_from_grobid_xml", label: "Parsed references from Grobid xml" },
    { value: "anystyle_references_from_cermine_fulltext", label: "Parsed references via Anystyle using Cermine fulltext" },
    { value: "anystyle_references_from_pdftotext_fulltext", label: "Parsed references via Anystyle using Pdftotext fulltext" },
    { value: "anystyle_references_from_cermine_refstrings", label: "Parsed references via Anystyle using Cermine refstrings" },
    { value: "anystyle_references_from_grobid_refstrings", label: "Parsed references via Anystyle using Grobid refstrings" },
    { value: "exparser_references_from_cermine_layout", label: "Parsed references via Exparser using Cermine layout" },
    { value: "url", label: "Url" },
    { value: "language", label: "Language" },
    { value: "subjects", label: "Subjects" },
    { value: "outliers", label: "Outliers" },
    { value: "sowiport_ids", label: "Sowiport_ids" },
    { value: "crossref_ids", label: "Crossref_ids" },
    { value: "dnb_ids", label: "Dnb_ids" },
    { value: "openalex_ids", label: "Openalex_ids" },
    { value: "ssoar_ids", label: "Ssoar_ids" },
    { value: "arxiv_ids", label: "Arxiv_ids" },
    { value: "gesis_bib_ids", label: "Gesis_bib_ids" },
    { value: "research_data_ids", label: "Research_data_ids" },
    { value: "econbiz_ids", label: "Econbiz_ids" },
    { value: "sowiport_urls", label: "Sowiport_urls" },
    { value: "crossref_urls", label: "Crossref_urls" },
    { value: "dnb_urls", label: "Dnb_urls" },
    { value: "openalex_urls", label: "Openalex_urls" },
    { value: "bing_urls", label: "Bing_urls" },
    { value: "ssoar_urls", label: "Ssoar_urls" },
    { value: "arxiv_urls", label: "Arxiv_urls" },
    { value: "gesis_bib_urls", label: "Gesis_bib_urls" },
    { value: "research_data_urls", label: "Research_data_urls" },
    { value: "econbiz_urls", label: "Econbiz_urls" },
    { value: "ssoar_general_urls", label: "Ssoar_general_urls" },
    { value: "arxiv_general_urls", label: "Arxiv_general_urls" },
    { value: "research_data_general_urls", label: "Research_data_general_urls" },
    { value: "openalex_general_urls", label: "Openalex_general_urls" },
    { value: "econbiz_general_urls", label: "Econbiz_general_urls" },
    { value: "crossref_general_urls", label: "Crossref_general_urls" },
    { value: "extracted_general_urls", label: "Extracted_general_urls" },
    { value: "ssoar_core_fulltext_urls", label: "Ssoar_core_fulltext_urls" },
    { value: "arxiv_core_fulltext_urls", label: "Arxiv_core_fulltext_urls" },
    { value: "research_data_core_fulltext_urls", label: "Research_data_core_fulltext_urls" },
    { value: "openalex_core_fulltext_urls", label: "Openalex_core_fulltext_urls" },
    { value: "econbiz_core_fulltext_urls", label: "Econbiz_core_fulltext_urls" },
    { value: "crossref_core_fulltext_urls", label: "Crossref_core_fulltext_urls" },
    { value: "extracted_core_fulltext_urls", label: "Extracted_core_fulltext_urls" },
    { value: "ssoar_unpaywall_fulltext_urls", label: "Ssoar_unpaywall_fulltext_urls" },
    { value: "arxiv_unpaywall_fulltext_urls", label: "Arxiv_unpaywall_fulltext_urls" },
    { value: "research_data_unpaywall_fulltext_urls", label: "Research_data_unpaywall_fulltext_urls" },
    { value: "openalex_unpaywall_fulltext_urls", label: "Openalex_unpaywall_fulltext_urls" },
    { value: "econbiz_unpaywall_fulltext_urls", label: "Econbiz_unpaywall_fulltext_urls" },
    { value: "crossref_unpaywall_fulltext_urls", label: "Crossref_unpaywall_fulltext_urls" },
    { value: "extracted_unpaywall_fulltext_urls", label: "Extracted_unpaywall_fulltext_urls" },
    { value: "block_ids", label: "Block_ids" },
    { value: "cluster_ids", label: "Cluster_ids" },
    { value: "duplicate_ids", label: "Duplicate_ids" },
    { value: "matched_references_from_sowiport", label: "Matched_references_from_sowiport"},
    { value: "matched_references_from_dnb", label: "Matched_references_from_dnb"},
    { value: "matched_references_from_crossref", label: "Matched_references_from_crossref"},
    { value: "matched_references_from_openalex", label: "Matched_references_from_openalex"},
    { value: "matched_references_from_ssoar", label: "Matched_references_from_ssoar"},
    { value: "matched_references_from_arxiv", label: "Matched_references_from_arxiv"},
    { value: "matched_references_from_gesis_bib", label: "Matched_references_from_gesis_bib"},
    { value: "matched_references_from_research_data", label: "Matched_references_from_research_data"},
    { value: "matched_references_from_econbiz", label: "Matched_references_from_econbiz"},
    { value: "crossref_references_by_matching", label: "Crossref_references_by_matching"},
    { value: "openalex_references_by_matching", label: "Openalex_references_by_matching"},
    { value: "sowiport_references_by_matching", label: "Sowiport_references_by_matching"},
    { value: "dnb_references_by_matching", label: "Dnb_references_by_matching"},
    { value: "ssoar_references_by_matching", label: "Ssoar_references_by_matching"},
    { value: "arxiv_references_by_matching", label: "Arxiv_references_by_matching"},
    { value: "gesis_bib_references_by_matching", label: "Gesis_bib_references_by_matching"},
    { value: "research_data_references_by_matching", label: "Research_data_references_by_matching"},
    { value: "econbiz_references_by_matching", label: "Econbiz_references_by_matching"},
];


// export const fieldNames = [
//     { value: "@id", label: "Id" },
//     { value: "@type", label: "Type" },
//     { value: "abstract", label: "Abstract" },
//     { value: "authors", label: "Authors" },
//     { value: "contributors", label: "Contributors" },
//     { value: "dates", label: "Dates" },
//     { value: "title", label: "Title" },
//     { value: "doctype", label: "Doctype" },
//     { value: "subjects", label: "Subjects" },
//     { value: "url", label: "Url" },
//     { value: "urn", label: "Urn" },
//     { value: "issn", label: "Issn" },
//     { value: "language", label: "Language" },
//     { value: "issue", label: "Issue" },
//     { value: "note", label: "Note" },
//     { value: "pdf", label: "Pdf" },
//     { value: "development", label: "Development" },
//     { value: "xml", label: "Xml" },
//     { value: "fulltext", label: "Fulltext" },
//     { value: "gold_fulltext", label: "Gold_fulltext" },
//     { value: "gold_refobjects", label: "Gold_refobjects" },
//     { value: "gold_refstrings", label: "Gold_refstrings" },
//     { value: "cermine_fulltext", label: "Cermine_fulltext" },
//     { value: "cermine_xml", label: "Cermine_xml" },
//     { value: "cermine_layout", label: "Cermine_layout" },
//     { value: "cermine_references_from_cermine_xml", label: "Cermine_references_from_cermine_xml" },
//     { value: "cermine_references_from_grobid_refstrings", label: "Cermine_references_from_grobid_refstrings" },
//     { value: "cermine_references_from_gold_refstrings", label: "Cermine_references_from_gold_refstrings" },
//     { value: "grobid_references_from_grobid_xml", label: "Grobid_references_from_grobid_xml" },
//     { value: "anystyle_references_from_cermine_fulltext", label: "Anystyle_references_from_cermine_fulltext" },
//     { value: "anystyle_references_from_cermine_refstrings", label: "Anystyle_references_from_cermine_refstrings" },
//     { value: "anystyle_references_from_gold_fulltext", label: "Anystyle_references_from_gold_fulltext" },
//     { value: "anystyle_references_from_gold_refstrings", label: "Anystyle_references_from_gold_refstrings" },
//     { value: "anystyle_references_from_grobid_refstrings", label: "Anystyle_references_from_grobid_refstrings" },
//     { value: "anystyle_xml_from_gold_fulltext", label: "Anystyle_xml_from_gold_fulltext" },
//     { value: "anystyle_xml_from_grobid_fulltext", label: "Anystyle_xml_from_grobid_fulltext" },
//     { value: "anystyle_xml_from_cermine_fulltext", label: "Anystyle_xml_from_cermine_fulltext" },
//     { value: "crossref_dois", label: "Crossref_dois" },
//     { value: "crossref_ids", label: "Crossref_ids" },
//     { value: "crossref_urls", label: "Crossref_urls" },
//     { value: "dnb_ids", label: "Dnb_ids" },
//     { value: "dnb_urls", label: "Dnb_urls" },
//     { value: "sowiport_ids", label: "Sowiport_ids" },
//     { value: "sowiport_urls", label: "Sowiport_urls" },
//     { value: "openalex_ids", label: "Openalex_ids" },
//     { value: "openalex_urls", label: "Openalex_urls" },
//     { value: "genre", label: "Genre" },
//     { value: "gesis_ids", label: "Gesis_ids" },
//     { value: "results", label: "Results" },
//     { value: "results_anystyle_references_from_cermine_fulltext", label: "Results_anystyle_references_from_cermine_fulltext" },
//     { value: "results_anystyle_references_from_cermine_refstrings", label: "Results_anystyle_references_from_cermine_refstrings" },
//     { value: "results_anystyle_references_from_gold_fulltext", label: "Results_anystyle_references_from_gold_fulltext" },
//     { value: "results_anystyle_references_from_gold_refstrings", label: "Results_anystyle_references_from_gold_refstrings" },
//     { value: "results_anystyle_references_from_grobid_refstrings", label: "Results_anystyle_references_from_grobid_refstrings" },
//     { value: "results_cermine_references_from_cermine_xml", label: "Results_cermine_references_from_cermine_xml" },
//     { value: "results_cermine_references_from_gold_refstrings", label: "Results_cermine_references_from_gold_refstrings" },
//     { value: "results_cermine_references_from_grobid_refstrings", label: "Results_cermine_references_from_grobid_refstrings" },
//     { value: "results_grobid_references_from_grobid_xml", label: "Results_grobid_references_from_grobid_xml" },
//     { value: "num_anystyle_references_from_cermine_fulltext", label: "Num_anystyle_references_from_cermine_fulltext" },
//     { value: "num_anystyle_references_from_cermine_refstrings", label: "Num_anystyle_references_from_cermine_refstrings" },
//     { value: "num_anystyle_references_from_gold_fulltext", label: "Num_anystyle_references_from_gold_fulltext" },
//     { value: "num_anystyle_references_from_gold_refstrings", label: "Num_anystyle_references_from_gold_refstrings" },
//     { value: "num_anystyle_references_from_grobid_fulltext", label: "Num_anystyle_references_from_grobid_fulltext" },
//     { value: "num_anystyle_references_from_grobid_refstrings", label: "Num_anystyle_references_from_grobid_refstrings" },
//     { value: "num_cermine_references_from_cermine_xml", label: "Num_cermine_references_from_cermine_xml" },
//     { value: "num_cermine_references_from_gold_refstrings", label: "Num_cermine_references_from_gold_refstrings" },
//     { value: "num_cermine_references_from_grobid_refstrings", label: "Num_cermine_references_from_grobid_refstrings" },
//     { value: "num_grobid_references_from_grobid_xml", label: "Num_grobid_references_from_grobid_xml" },
//     { value: "has_anystyle_references_from_cermine_fulltext", label: "Has_anystyle_references_from_cermine_fulltext" },
//     { value: "has_anystyle_references_from_cermine_refstrings", label: "Has_anystyle_references_from_cermine_refstrings" },
//     { value: "has_anystyle_references_from_grobid_fulltext", label: "Has_anystyle_references_from_grobid_fulltext" },
//     { value: "has_anystyle_references_from_gold_fulltext", label: "Has_anystyle_references_from_gold_fulltext" },
//     { value: "has_anystyle_references_from_gold_refstrings", label: "Has_anystyle_references_from_gold_refstrings" },
//     { value: "has_anystyle_references_from_grobid_refstrings", label: "Has_anystyle_references_from_grobid_refstrings" },
//     { value: "has_anystyle_xml_from_cermine_fulltext", label: "Has_anystyle_xml_from_cermine_fulltext" },
//     { value: "has_anystyle_xml_from_gold_fulltext", label: "Has_anystyle_xml_from_gold_fulltext" },
//     { value: "has_anystyle_xml_from_grobid_fulltext", label: "Has_anystyle_xml_from_grobid_fulltext" },
//     { value: "has_cermine_fulltext", label: "Has_cermine_fulltext" },
//     { value: "has_cermine_layout", label: "Has_cermine_layout" },
//     { value: "has_cermine_references_from_cermine_xml", label: "Has_cermine_references_from_cermine_xml" },
//     { value: "has_cermine_references_from_gold_refstrings", label: "Has_cermine_references_from_gold_refstrings" },
//     { value: "has_cermine_references_from_grobid_refstrings", label: "Has_cermine_references_from_grobid_refstrings" },
//     { value: "has_cermine_xml", label: "Has_cermine_xml" },
//     { value: "has_crossref_dois", label: "Has_crossref_dois" },
//     { value: "has_crossref_ids", label: "Has_crossref_ids" },
//     { value: "has_crossref_urls", label: "Has_crossref_urls" },
//     { value: "has_dnb_ids", label: "Has_dnb_ids" },
//     { value: "has_dnb_urls", label: "Has_dnb_urls" },
//     { value: "has_fulltext", label: "Has_fulltext" },
//     { value: "has_gesis_ids", label: "Has_gesis_ids" },
//     { value: "has_gold_fulltext", label: "Has_gold_fulltext" },
//     { value: "has_gold_refobjects", label: "Has_gold_refobjects" },
//     { value: "has_gold_refstrings", label: "Has_gold_refstrings" },
//     { value: "has_grobid_references_from_grobid_xml", label: "Has_grobid_references_from_grobid_xml" },
//     { value: "has_openalex_ids", label: "Has_openalex_ids" },
//     { value: "has_openalex_urls", label: "Has_openalex_urls" },
//     { value: "has_pdf", label: "Has_pdf" },
//     { value: "has_results", label: "Has_results" },
//     { value: "has_results_anystyle_references_from_cermine_fulltext", label: "Has_results_anystyle_references_from_cermine_fulltext" },
//     { value: "has_results_anystyle_references_from_cermine_refstrings", label: "Has_results_anystyle_references_from_cermine_refstrings" },
//     { value: "has_results_anystyle_references_from_gold_fulltext", label: "Has_results_anystyle_references_from_gold_fulltext" },
//     { value: "has_results_anystyle_references_from_gold_refstrings", label: "Has_results_anystyle_references_from_gold_refstrings" },
//     { value: "has_results_anystyle_references_from_grobid_fulltext", label: "Has_results_anystyle_references_from_grobid_fulltext" },
//     { value: "has_results_anystyle_references_from_grobid_refstrings", label: "Has_results_anystyle_references_from_grobid_refstrings" },
//     { value: "has_results_cermine_references_from_cermine_xml", label: "Has_results_cermine_references_from_cermine_xml" },
//     { value: "has_results_cermine_references_from_gold_refstrings", label: "Has_results_cermine_references_from_gold_refstrings" },
//     { value: "has_results_cermine_references_from_grobid_refstrings", label: "Has_results_cermine_references_from_grobid_refstrings" },
//     { value: "has_results_grobid_references_from_grobid_xml", label: "Has_results_grobid_references_from_grobid_xml" },
//     { value: "has_sowiport_ids", label: "Has_sowiport_ids" },
//     { value: "has_sowiport_urls", label: "Has_sowiport_urls" },
//     { value: "has_xml", label: "Has_xml" }
// ];