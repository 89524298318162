import React, {Component} from "react";
import './styles/Accordions.sass'
import {
    Accordion,
    AccordionItem,
    AccordionItemButton,
    AccordionItemHeading,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import ReactTooltip from 'react-tooltip';
// import JSONViewer from 'react-json-viewer';
// import {JSONTree} from 'react-json-tree';
import ReactJson from 'react-json-view'
import {fieldNames} from "../data/fieldNames";


// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';

class Accordions extends Component {
    constructor(props) {
        super(props);

        this.state = {
            itemKey: null,
            showRef: false,
            refInd: null
        };
        this.getExpandableKey = this.getExpandableKey.bind(this)
        this.showReferenceString = this.showReferenceString.bind(this)
        this.hideReferenceString = this.hideReferenceString.bind(this)
        this.isValidUrl = this.isValidUrl.bind(this)
        this.fieldsToDisplay = this.fieldsToDisplay.bind(this)
    }

    getExpandableKey(key) {
        this.setState({
            itemKey: key
        })
    }

    showReferenceString(ind) {
        // console.log(typeof(ind), ind)
        this.setState({refInd: ind, showRef: true})
    }

    hideReferenceString() {
        this.setState({showRef: false})
    }

    isValidUrl(string) {
        let url;

        try {
            url = new URL(string);
        } catch (_) {
            return false;
        }

        return url.protocol === "http:" || url.protocol === "https:";
    }

    fieldsToDisplay(fieldNames, field) {
        return fieldNames.some(el => el.value === field);
    }

    theme = {
        scheme: 'summerfruit',
        author: 'christopher corley (http://cscorley.github.io/)',
        base00: '#151515',
        base01: '#202020',
        base02: '#303030',
        base03: '#505050',
        base04: '#B0B0B0',
        base05: '#D0D0D0',
        base06: '#E0E0E0',
        base07: '#FFFFFF',
        base08: '#FF0086',
        base09: '#FD8900',
        base0A: '#ABA800',
        base0B: '#00C918',
        base0C: '#1faaaa',
        base0D: '#3777E6',
        base0E: '#AD00A1',
        base0F: '#cc6633'
    }

    render() {
        return <Accordion className='accordion-layout' preExpanded={this.props.uuid ? [this.props.uuid] : []}
                          allowZeroExpanded allowMultipleExpanded>
            {/*<h4 className='margin-left'>{this.props.dataset}</h4>*/}
            <h4 className='margin-left'>RESULT</h4>
            {this.props.hits.map((doc, ind) => (
                <AccordionItem key={doc._id} uuid={doc._id}>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            {doc._id}
                            {!this.props.results ?
                                <button type="button" className="btn btn-sm btn-outline-danger col-2 right-button"
                                        key={ind} onClick={() => {
                                    this.props.getIdIndex(doc._id, doc._index.includes('geocite') ? 'geocite'
                                        : doc._index.includes('ssoar_gold') ? 'ssoar_gold'
                                            : doc._index.includes('outcite_ssoar') ? 'outcite_ssoar'
                                                : 'users', null)
                                }}>Result</button> : null}
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <Accordion className='no-border' allowMultipleExpanded allowZeroExpanded>
                            {Object.entries(doc['_source']).map((src, src_ind) => (
                                this.fieldsToDisplay(fieldNames, src[0]) ?
                                    Array.isArray(src[1]) && src[1].length > 0 ?
                                        <AccordionItem key={src_ind}>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    {/*{src[0]}*/}
                                                    {fieldNames.map((field, f_ind)=>
                                                        src[0]===field["value"]?field["label"]:null
                                                    )}
                                                    {!this.props.results ? <button type="button"
                                                                                   className="btn btn-sm btn-outline-dark col-2 right-button"
                                                                                   key={ind} onClick={() => {
                                                        this.props.getIdIndex(doc._id, doc._index.includes('geocite') ? 'geocite'
                                                            : doc._index.includes('ssoar_gold') ? 'ssoar_gold'
                                                                : doc._index.includes('outcite_ssoar') ? 'outcite_ssoar'
                                                                    : 'users', src[0])
                                                    }}>Result</button> : null}
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                {src[0] === 'authors' || src[0] === 'contributors' ?
                                                    // src[1] && (typeof src[1][0] === 'object' || Array.isArray(src[1][0])) ?
                                                    // <JSONTree data={src[1]} theme={this.theme}
                                                    //           // getItemString={(persName) => <span>{persName}</span>}
                                                    // />
                                                    <ReactJson src={src[1]} />
                                                    // <pre>
                                                    //     <JSONViewer json={src[1]}/>
                                                    // </pre>
                                                    :
                                                    typeof src[1][0] === 'string' || src[1][0] instanceof String ?
                                                        <ul>
                                                            {src[1].map((item, item_ind) => (
                                                                <li className='wrap' key={item_ind}>{item}</li>
                                                            ))}
                                                        </ul>
                                                        :
                                                        <ol className='json-style parent' id='json'>
                                                            {src[1].map((item, item_ind) => (
                                                                !('is_outlier' in item )||('is_outlier' in item && !item['is_outlier']) ?
                                                                    <li key={item_ind}>

                                                                        {
                                                                            'type' in item && item['type'] ?
                                                                            <span key={item['type'] + item_ind}>
                                                                                [<span className='wrap type'>
                                                                                {item['type']}
                                                                                    </span>]&nbsp;
                                                                            </span> : null
                                                                        }

                                                                        {
                                                                            'title' in item && item['title'] ? <span> "
                                                                            <span key={'title' + item_ind}
                                                                                  className={this.state.showRef && this.state.refInd === item_ind.toString() ? 'wrap hover-title' : 'wrap title'}
                                                                                  onMouseOver={() => this.showReferenceString(item_ind.toString())}
                                                                                  onMouseLeave={() => this.hideReferenceString()}
                                                                                  data-tip='tooltip'
                                                                                  data-for={item_ind.toString()}><b>{item['title']}</b></span>
                                                                                            {this.state.showRef && this.state.refInd === item_ind.toString() && 'reference' in item && item['reference'] ?
                                                                                                <ReactTooltip
                                                                                                    id={item_ind.toString()}
                                                                                                    type='info' place='right'
                                                                                                    effect='float'>
                                                                                                    <span>{item['reference']}</span>
                                                                                                </ReactTooltip> : null}

                                                                                            "<br/>
                                                                            </span>
                                                                                // :
                                                                                // !('title' in item) && 'reference' in item && item['reference']?
                                                                                //     <span>"<span key={'reference' + item_ind} className='wrap title'><b>{item['reference']}</b></span>"<br/></span>
                                                                            :
                                                                            null
                                                                        }

                                                                        {
                                                                            'authors' in item && Array.isArray(item['authors']) && item['authors'].length > 0 ?
                                                                                <span key={'authors' + item_ind}>
                                                                                    {item['authors'].map((a, i) => i !== item['authors'].length - 1 ?
                                                                                        <span>
                                                                                            <span className='wrap authors'>{a['author_string']}</span>
                                                                                            {'author_string' in a?<span>,&nbsp;</span>:null}
                                                                                        </span>
                                                                                        : <span>
                                                                                            <span className='wrap authors'>{a['author_string']}</span>
                                                                                            {'year' in item && item['year'] ?
                                                                                                <span> (<span className='wrap year'>{item['year']}</span>)</span> : null}
                                                                                        </span>
                                                                                    )}
                                                                                        <br/>
                                                                                </span>
                                                                                : 'year' in item && item['year'] ?
                                                                                    <span key={'year' + item_ind}>(<span className='wrap year'>{item['year']}</span>)<br/></span>
                                                                                : null
                                                                        }

                                                                        {
                                                                            'source' in item && item['source'] ?
                                                                                <span key={'source' + item_ind}>
                                                                                    <span
                                                                                        className='wrap source'>{item['source']}</span>
                                                                                                {'volume' in item && item['volume'] ?
                                                                                                    <span>, <span
                                                                                                        className='wrap volume'>{item['volume']}</span></span> : null}
                                                                                                {'issue' in item && item['issue'] ?
                                                                                                    <span>, (<span
                                                                                                        className='wrap issue'>{item['issue']}</span>)</span> : null}
                                                                                                {'start' in item && item['start'] ?
                                                                                                    <span>, pp. <span
                                                                                                        className='wrap pages'>{item['start']}</span>
                                                                                                        {'end' in item && item['end'] ?
                                                                                                            <span> - <span
                                                                                                                className='wrap pages'>{item['end']}</span></span> : null}
                                                                                                    </span> : null}
                                                                                    <br/>
                                                                                </span>
                                                                                : 'volume' in item && item['volume'] ?
                                                                                    <span className='wrap volume'
                                                                                          key={'volume' + item_ind}>{item['volume']}
                                                                                        {'issue' in item && item['issue'] ?
                                                                                            <span>, (<span
                                                                                                className='wrap issue'>{item['issue']}</span>)</span> : null}
                                                                                        {'start' in item && item['start'] ?
                                                                                            <span>, pp. <span
                                                                                                className='wrap pages'>{item['start']}</span>
                                                                                                {'end' in item && item['end'] ?
                                                                                                    <span> - <span
                                                                                                        className='wrap pages'>{item['end']}</span></span> : null}
                                                                                                </span> : null}
                                                                                        <br/>
                                                                                    </span>
                                                                                : 'issue' in item && item['issue'] ?
                                                                                    <span key={'issue' + item_ind}>
                                                                                        (<span className='wrap issue'>{item['issue']}</span>)
                                                                                                {'start' in item && item['start'] ?
                                                                                                    <span>, pp. <span
                                                                                                        className='wrap pages'>{item['start']}</span>
                                                                                                        {'end' in item && item['end'] ?
                                                                                                            <span> - <span
                                                                                                                className='wrap pages'>{item['end']}</span></span> : null}
                                                                                                        <br/></span> : null}
                                                                                    </span>
                                                                                : 'start' in item && item['start'] ?
                                                                                    <span
                                                                                        key={'pages' + item_ind}>pp. <span
                                                                                        className='wrap pages'>{item['start']}</span>
                                                                                        {'end' in item && item['end'] ?
                                                                                            <span> - <span
                                                                                                className='wrap pages'>{item['end']}</span></span> : null}
                                                                                        <br/>
                                                                                    </span>
                                                                                : null
                                                                        }

                                                                        {
                                                                            'editors' in item && Array.isArray(item['editors']) && item['editors'].length > 0 ?
                                                                                <span key={'editors' + item_ind}>
                                                                                    {item['editors'].map((e, i) => i !== item['editors'].length - 1 ?
                                                                                        <span><span
                                                                                            className='wrap editors'>{e['editor_string']}</span>, </span> :
                                                                                        <span><span
                                                                                            className='wrap editors'>{e['editor_string']}</span><i> (Edts.)</i><br/></span>)}
                                                                                </span>
                                                                            : null
                                                                        }

                                                                        {
                                                                            'publishers' in item && Array.isArray(item['publishers']) && item['publishers'].length > 0 ?
                                                                                <span key={'publishers' + item_ind}>
                                                                                    {item['publishers'].map((p, i) => i !== item['publishers'].length - 1 ?
                                                                                        <span><span
                                                                                            className='wrap publishers'>{p['publisher_string']}</span>, </span>
                                                                                        : 'place' in item && item['place'] ?
                                                                                            <span><span
                                                                                                className='wrap publishers'>{p['publisher_string']}</span>, <span
                                                                                                className='wrap place'>{item['place']}</span></span>
                                                                                            :
                                                                                            <span className='wrap publishers'>
                                                                                                {p['publisher_string']}
                                                                                                {p['publisher_string']?<span></span>:null}
                                                                                            </span>
                                                                                    )}
                                                                                        <br/>
                                                                                </span>
                                                                            : 'place' in item && item['place'] ?
                                                                                <span className='wrap place'
                                                                                      key={'place' + item_ind}>{item['place']}<br/></span>
                                                                                : null
                                                                        }

                                                                        {
                                                                            'sowiport_id' in item && item['sowiport_id'] ?
                                                                                <span
                                                                                    key={'sowiport' + item_ind}><b>GESIS: </b>
                                                                                    {
                                                                                        'sowiport_url' in item && item['sowiport_url'] ?
                                                                                            <a className='wrap'
                                                                                               href={item['sowiport_url']}
                                                                                               target='_blank'
                                                                                               rel='noreferrer'>{item['sowiport_id']}</a>
                                                                                            : item['sowiport_id']
                                                                                    } </span> : null
                                                                        }

                                                                        {
                                                                            'crossref_id' in item && item['crossref_id'] ?
                                                                                <span
                                                                                    key={'crossref' + item_ind}><b> CROSSREF: </b>
                                                                                    {
                                                                                        'crossref_url' in item && item['crossref_url'] ?
                                                                                            <a className='wrap'
                                                                                               href={item['crossref_url']}
                                                                                               target='_blank'
                                                                                               rel='noreferrer'>{item['crossref_id']}</a>
                                                                                            : item['crossref_id']
                                                                                    } </span> : null
                                                                        }

                                                                        {
                                                                            'openalex_id' in item && item['openalex_id'] ?
                                                                                <span
                                                                                    key={'openalex' + item_ind}><b> OPENALEX: </b>
                                                                                    {'openalex_url' in item && item['openalex_url'] ?
                                                                                        <a className='wrap'
                                                                                           href={item['openalex_url']}
                                                                                           target='_blank'
                                                                                           rel='noreferrer'>{item['openalex_id']}</a>
                                                                                        : item['openalex_id']
                                                                                    }
                                                                                </span> : null
                                                                        }

                                                                        {
                                                                            'dnb_id' in item && item['dnb_id'] ?
                                                                                <span
                                                                                    key={'dnb' + item_ind}><b> DNB: </b>
                                                                                    {'dnb_url' in item && item['dnb_url'] ?
                                                                                        <a className='wrap'
                                                                                           href={item['dnb_url']}
                                                                                           target='_blank'
                                                                                           rel='noreferrer'>{this.is_outlier_flag ?
                                                                                            <s>{item['dnb_id']}</s> : item['dnb_id']}</a>
                                                                                        : this.is_outlier_flag ?
                                                                                            <s>{item['dnb_id']}</s> : item['dnb_id']
                                                                                    }
                                                                                </span> : null
                                                                        }

                                                                        {
                                                                            'ssoar_id' in item && item['ssoar_id'] ?
                                                                                <span
                                                                                    key={'ssoar' + item_ind}><b> SSOAR: </b>
                                                                                    {'ssoar_url' in item && item['ssoar_url'] ?
                                                                                        <a className='wrap'
                                                                                           href={item['ssoar_url']}
                                                                                           target='_blank'
                                                                                           rel='noreferrer'>{item['ssoar_id']}</a>
                                                                                        : item['ssoar_id']
                                                                                    }
                                                                                </span> : null
                                                                        }

                                                                        {
                                                                            'arxiv_id' in item && item['arxiv_id'] ?
                                                                                <span
                                                                                    key={'arxiv' + item_ind}><b> ARXIV: </b>
                                                                                    {'arxiv_url' in item && item['arxiv_url'] ?
                                                                                        <a className='wrap'
                                                                                           href={item['arxiv_url']}
                                                                                           target='_blank'
                                                                                           rel='noreferrer'>{item['arxiv_id']}</a>
                                                                                        : item['arxiv_id']
                                                                                    }
                                                                                </span> : null
                                                                        }

                                                                        {
                                                                            'gesis_bib_id' in item && item['gesis_bib_id'] ?
                                                                                <span
                                                                                    key={'gesis_bib' + item_ind}><b> GESIS BIB: </b>
                                                                                    {'gesis_bib_url' in item && item['gesis_bib_url'] ?
                                                                                        <a className='wrap'
                                                                                           href={item['gesis_bib_url']}
                                                                                           target='_blank'
                                                                                           rel='noreferrer'>{item['gesis_bib_id']}</a>
                                                                                        : item['gesis_bib_id']
                                                                                    }
                                                                            </span> : null
                                                                        }

                                                                        {
                                                                            'research_data_id' in item && item['research_data_id'] ?
                                                                                <span
                                                                                    key={'research_data' + item_ind}><b> RESEARCH DATA: </b>
                                                                                    {'research_data_url' in item && item['research_data_url'] ?
                                                                                        <a className='wrap'
                                                                                           href={item['research_data_url']}
                                                                                           target='_blank'
                                                                                           rel='noreferrer'>{item['research_data_id']}</a>
                                                                                        : item['research_data_id']
                                                                                    }
                                                                                </span> : null
                                                                        }

                                                                        {
                                                                            'econbiz_id' in item && item['econbiz_id'] ?
                                                                                <span
                                                                                    key={'econbiz_id' + item_ind}><b> ECONBIZ: </b>
                                                                                    {'econbiz_url' in item && item['econbiz_url'] ?
                                                                                        <a className='wrap'
                                                                                           href={item['econbiz_url']}
                                                                                           target='_blank'
                                                                                           rel='noreferrer'>{item['econbiz_id']}</a>
                                                                                        : item['econbiz_id']
                                                                                    }
                                                                                </span> : null
                                                                        }

                                                                        {
                                                                            'fulltext_url' in item && item['fulltext_url'] ?
                                                                                <span
                                                                                    key={'fulltext_url' + item_ind}><b> FULLTEXT: </b>
                                                                                    <a className='wrap'
                                                                                       href={this.isValidUrl(item['fulltext_url'])?item['fulltext_url']:'//'+item['fulltext_url']} //if no protocol http ot https available, appending '//' tells the browser that this is actually a new (full) link, and not a relative one (relative to your current website's link).
                                                                                       target='_blank'
                                                                                       rel='noreferrer'>{this.isValidUrl(item['fulltext_url'])?new URL(item['fulltext_url']).hostname.replace('www.',''):new URL("https://"+item['fulltext_url']).hostname.replace('www.','')}</a>
                                                                            </span> : null
                                                                        }

                                                                        {
                                                                            'general_url' in item && item['general_url'] ?
                                                                                <span
                                                                                    key={'general_url' + item_ind}><b> URL: </b>
                                                                                    <a className='wrap'
                                                                                       href={item['general_url']}
                                                                                       target='_blank'
                                                                                       rel='noreferrer'>{this.isValidUrl(item['general_url'])?new URL(item['general_url']).hostname.replace('www.',''):new URL("https://"+item['general_url']).hostname.replace('www.','')}</a>
                                                                                </span> : null
                                                                        }

                                                                        {
                                                                            'bing_url' in item && item['bing_url'] ?
                                                                                <span
                                                                                    key={'bing_url' + item_ind}><b> BING: </b>
                                                                                    <a className='wrap'
                                                                                       href={item['bing_url']}
                                                                                       target='_blank'
                                                                                       rel='noreferrer'>{this.isValidUrl(item['bing_url'])?new URL(item['bing_url']).hostname.replace('www.',''):new URL("https://"+item['bing_url']).hostname.replace('www.','')}</a>
                                                                                </span> : null
                                                                        }

                                                                        {
                                                                            !(('sowiport_id' in item && item['sowiport_id']) || ('crossref_id' in item && item['crossref_id']) || ('openalex_id' in item && item['openalex_id']) || ('dnb_id' in item && item['dnb_id']) || ('ssoar_id' in item && item['ssoar_id']) || ('arxiv_id' in item && item['arxiv_id']) || ('gesis_bib_id' in item && item['gesis_bib_id']) || ('research_data_id' in item && item['research_data_id']) || ('econbiz_id' in item && item['econbiz_id']) || ('fulltext_url' in item && item['fulltext_url']) || ('general_url' in item && item['general_url']) || ('bing_url' in item && item['bing_url'])) ?
                                                                                <span key={'web-search' + item_ind}>
                                                                                    <b>SEARCH ON WEB: </b>
                                                                                    <a className='web-search'
                                                                                       href={'https://www.google.com/search?q=' + encodeURIComponent(item['reference'])}
                                                                                       target='_blank'
                                                                                       rel='noreferrer'>  &#60;GOOGLE&#62;   </a>
                                                                                    <a className='web-search'
                                                                                       href={'https://scholar.google.com/scholar?q=' + encodeURIComponent(item['reference'])}
                                                                                       target='_blank'
                                                                                       rel='noreferrer'>  &#60;GOOGLE SCHOLAR&#62;   </a>
                                                                                    <a className='web-search'
                                                                                       href={'https://www.bing.com/search?q=' + encodeURIComponent(item['reference'])}
                                                                                       target='_blank'
                                                                                       rel='noreferrer'>  &#60;BING&#62;   </a>
                                                                                </span> : null
                                                                        }

                                                                        {/*{*/}
                                                                        {/*    <span*/}
                                                                        {/*        key={'web-search' + item_ind}>*/}
                                                                        {/*        <b>SEARCH ON WEB: </b>*/}
                                                                        {/*        <a className='web-search'*/}
                                                                        {/*           href={'https://www.google.com/search?q=' + item['reference']}*/}
                                                                        {/*           target='_blank'*/}
                                                                        {/*           rel='noreferrer'>  &#60;GOOGLE&#62;   </a>*/}
                                                                        {/*        <a className='web-search'*/}
                                                                        {/*           href={'https://scholar.google.com/scholar?q=' + item['reference']}*/}
                                                                        {/*           target='_blank'*/}
                                                                        {/*           rel='noreferrer'>  &#60;GOOGLE SCHOLAR&#62;   </a>*/}
                                                                        {/*        <a className='web-search'*/}
                                                                        {/*           href={'https://www.bing.com/search?q=' + item['reference']}*/}
                                                                        {/*           target='_blank'*/}
                                                                        {/*           rel='noreferrer'>  &#60;BING&#62;   </a>*/}
                                                                        {/*        /!*<a className='web-search'*!/*/}
                                                                        {/*        /!*   href={'title' in item?'https://www.semanticscholar.org/search?q=' + item['title'] : 'https://www.semanticscholar.org/search?q=' + item['reference']}*!/*/}
                                                                        {/*        /!*   target='_blank'*!/*/}
                                                                        {/*        /!*   rel='noreferrer'>  &#60;SEMANTIC SCHOLAR&#62;   </a>*!/*/}
                                                                        {/*    </span>*/}
                                                                        {/*}*/}
                                                                    </li>
                                                                    : null
                                                            ))}

                                                            {/*<pre>{JSON.stringify(src[1])}</pre>*/}
                                                            {/*<pre>*/}
                                                            {/*     <JSONTree data={src[1]} theme={this.theme}/>*/}
                                                            {/*invertTheme={false}*/}
                                                            {/*<JSONViewer json={src[1]}/>*/}
                                                            {/*</pre>*/}
                                                        </ol>
                                                }
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                        :
                                        typeof src[1] === 'object' && src[1] && Object.keys(src[1]).length !== 0 && !Array.isArray(src[1]) ?
                                            <AccordionItem key={src_ind}>
                                                <AccordionItemHeading>
                                                    <AccordionItemButton>
                                                        {/*{src[0]}*/}
                                                        {fieldNames.map((field, f_ind)=>
                                                            src[0]===field["value"]?field["label"]:null
                                                        )}
                                                        {!this.props.results ? <button type="button"
                                                                                       className="btn btn-sm btn-outline-dark col-2 right-button"
                                                                                       key={ind} onClick={() => {
                                                            this.props.getIdIndex(doc._id, doc._index.includes('geocite') ? 'geocite'
                                                                : doc._index.includes('ssoar_gold') ? 'ssoar_gold'
                                                                    : doc._index.includes('outcite_ssoar') ? 'outcite_ssoar'
                                                                        : 'users', src[0])
                                                        }}>Result</button> : null}
                                                    </AccordionItemButton>
                                                </AccordionItemHeading>
                                                <AccordionItemPanel>
                                                    {
                                                        Object.entries(src[1]).map((obj, obj_ind) => (
                                                            <AccordionItem key={obj_ind}>
                                                                <AccordionItemHeading>
                                                                    <AccordionItemButton>
                                                                        {obj[0]}
                                                                    </AccordionItemButton>
                                                                </AccordionItemHeading>
                                                                <AccordionItemPanel>
                                                                    {obj[1] && (typeof obj[1] === 'object' || Array.isArray(obj[1])) ?
                                                                        <ReactJson src={obj[1]} />
                                                                        // Object.entries(obj[1]).map((sub_obj, sub_obj_ind) => (
                                                                        //         <AccordionItem key={sub_obj_ind}>
                                                                        //             <AccordionItemHeading>
                                                                        //                 <AccordionItemButton>
                                                                        //                     {sub_obj[0]}
                                                                        //                 </AccordionItemButton>
                                                                        //             </AccordionItemHeading>
                                                                        //             <AccordionItemPanel>
                                                                        //                 <div className='json-style' id='json'>
                                                                        //                     <ReactJson src={sub_obj[1]} />
                                                                        //                 </div>
                                                                        //
                                                                        //             </AccordionItemPanel>
                                                                        //         </AccordionItem>
                                                                        //     )
                                                                        // )
                                                                        :
                                                                        <pre>{obj[1]}</pre>
                                                                    }
                                                                </AccordionItemPanel>
                                                            </AccordionItem>
                                                        ))
                                                    }
                                                </AccordionItemPanel>
                                            </AccordionItem>
                                            :
                                            typeof src[1] === 'string' || typeof src[1] === 'boolean' ?
                                                <AccordionItem key={src_ind}>
                                                    <AccordionItemHeading>
                                                        <AccordionItemButton>
                                                            {/*{src[0]}*/}
                                                            {fieldNames.map((field, f_ind)=>
                                                                src[0]===field["value"]?field["label"]:null
                                                            )}
                                                            {!this.props.results ? <button type="button"
                                                                                           className="btn btn-sm btn-outline-dark col-2 right-button"
                                                                                           key={ind} onClick={() => {
                                                                    this.props.getIdIndex(doc._id, doc._index.includes('geocite') ? 'geocite'
                                                                        : doc._index.includes('ssoar_gold') ? 'ssoar_gold'
                                                                            : doc._index.includes('outcite_ssoar') ? 'outcite_ssoar'
                                                                                : 'users', src[0])
                                                                }}>Result</button>
                                                                :
                                                                null
                                                            }
                                                        </AccordionItemButton>
                                                    </AccordionItemHeading>
                                                    <AccordionItemPanel>
                                                        {
                                                            this.isValidUrl(String(src[1])) ? <a className='wrap'
                                                                                                 href={String(src[1])}
                                                                                                 target='_blank'
                                                                                                 rel='noreferrer'>{src[1]}</a>
                                                                : <div className="wrap">{String(src[1])}</div>
                                                        }

                                                    </AccordionItemPanel>
                                                </AccordionItem>
                                                :
                                                null
                                    : null))}
                        </Accordion>
                    </AccordionItemPanel>
                </AccordionItem>
            ))}
            {this.props.hits.length >= 10 && !this.props.results ?
                <>
                    <button type="button" className="btn btn-success margin-top right-button"
                            onClick={() => this.props.updateResults(this.props.from + this.props.size, this.props.size)}> Next &raquo; </button>
                    <button type="button" className="btn btn-danger margin-top" disabled={this.props.from === 0}
                            onClick={() => this.props.from >= this.props.size ? this.props.updateResults(this.props.from - this.props.size, this.props.size) : null}> &laquo; Back
                    </button>
                </>
                : null
            }
        </Accordion>;

    }
}

export default Accordions;