import {Component} from "react";
import './styles/SearchBar.sass'

class SearchBar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            input: null,
        };
        this.handleChange = this.handleChange.bind(this)
        this.handleKeyDown = this.handleKeyDown.bind(this)
    }

    handleChange(e) {
        this.setState({input: e.target.value});
    }

    handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            return this.state.input && this.props.idSearch ? this.props.getResults(this.props.index, this.state.input, null, null, null)
                    :
                    this.state.input && this.props.globalSearch && this.props.index ? (
                            this.props.clearView(),
                            this.props.index.map((item, ind) => this.props.getResults(item, null, this.state.input, 0, 5)))
                            :
                            null
        }
    }

    render() {
        return <div className="input-group w-75 padding">
            {/*<div className={"input-group " + this.props.class}>*/}
            <input type="search" className="form-control rounded" placeholder={this.props.placeholder}
                   aria-label="Search"
                   aria-describedby="search-addon" onChange={this.handleChange} onKeyDown={this.handleKeyDown}/>
            <button type="button" className="btn btn-outline-danger" onClick={() =>
                this.state.input && this.props.idSearch ? this.props.getResults(this.props.index, this.state.input, null, null, null)
                    :
                    this.state.input && this.props.globalSearch && this.props.index ? (
                        this.props.clearView(),
                        this.props.index.map((item, ind) => this.props.getResults(item, null, this.state.input, 0, 5)))
                        :
                        null
            }
            >search
            </button>
        </div>;

    }
}

export default SearchBar;