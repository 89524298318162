// About.js
import React from 'react';
import './styles/About.sass';
import Button from "./Button";
import ssoar_logo from '../images/ssoar_logo.png';
import Dfg from '../images/dfg.jfif';
import Pipeline from '../images/pipeline.png';
import oc_logo from '../images/oc_logo.png';
import Footers from "./Footers";

const About = () => {
    return <div className="row align-items-center justify-content-center">
        <div className="col-md-11 col-border mt-3">
            <h2 className="text-center headings-color">About Project</h2>
            <p>
                <a href="/" target="_blank" rel="noopener noreferrer">
                OUTCITE</a>, the extension of <a className='wrap'
                                                target='_blank'
                                                rel='noreferrer'
                                                href='https://ieeexplore.ieee.org/document/8791194'>EXCITE</a>
                &nbsp;(Extraction of Citations from PDF Documents) is an initiative taken to improve the accessibility and linking of citation data, particularly in the social sciences. It focuses on linking references that are not easily found in existing databases, such as incomplete citations, and web resources. The project developed tools to process and match these "non-source items" to their original sources, thereby enhancing the completeness of citation records available for research.
            </p>

            <h4 className="headings-color"><Button iconName='Pipeline' size='lg'/> How it works?</h4>
            <p>
                It consists of the pipeline performing data extraction, matching, linking, deduplication and storing all the data to Elastic Search (ES) indices.
            </p>
            <p>
                <Button iconName='Cron' size='lg'/> CRON job runs a pipeline periodically on the dedicated server to process all the newly added publications in
                <a href="https://www.gesis.org/ssoar" target="_blank" rel="noopener noreferrer">
                    <img src={ssoar_logo} alt="ssoar" style={{ height: '30px', maxWidth:'50px', verticalAlign: 'middle', marginLeft: '5px' }}/>
                </a> since the last run. Furthermore, it provides the results to <a href="https://search.gesis.org/" target="_blank" rel="noopener noreferrer">GESIS-Search</a>  which are displayed for each publication. <a href="https://search.gesis.org/publication/gesis-ssoar-93176" target="_blank" rel="noopener noreferrer">Click here</a> to have a look at the references for example document.
            </p>
            <p>
                <Button iconName='Success' size='lg'/> As of the project’s completion, OUTCITE has processed over <strong>74,500 PDF</strong> documents from the SSOAR repository, ingesting more than <strong>3.5 million</strong> references into the GESIS Search database. About <strong>1.76 million</strong> of these references have been successfully linked to their online sources.
            </p>
            <p><Button iconName='Delivery' size='lg'/> Data delivery to <a href="https://opencitations.net/" target="_blank" rel="noopener noreferrer">
                <img src={oc_logo} alt="ssoar" style={{ height: '50px', maxWidth:'50px', verticalAlign: 'middle', marginLeft: '5px' }}/>OpenCitations
            </a> for further processing which is available on Gesis Box for download via following <a href="https://gesisbox.gesis.org/index.php/s/cGkXrs2eWpRptQm" target="_blank" rel="noopener noreferrer">link</a>.</p>
            <p><Button iconName='Git' size='lg'/> Code available publicly on <a href="https://github.com/orgs/OUTCITE/repositories" target="_blank" rel="noopener noreferrer">GitHub</a>.</p>

            <h5 className="headings-color"><Button iconName='Demo' size='lg'/> Public Demo</h5>
            <p>
                For public demo, the input PDF undergoes the following steps: Extraction, Matching, Linking and lastly stored in to the ES index with an assigned ID. The data for the requested ID is then returned and displayed as a response to your request.
            </p>
            <p style={{ textAlign: 'center' }}>
                <img src={Pipeline} alt="Pipeline" style={{ width: '100%', maxWidth: '700px', height: 'auto', display: 'block', margin: '0 auto' }}/>
            </p>
            <p>
                Try out the OUTCITE's <a href="/" target="_blank" rel="noopener noreferrer">demo</a> where you can trial the functionality.
                This URL may be subject to change or to removal after a period of time.
            </p>

            <h6 className="headings-color"><Button iconName='Limit' size='lg'/> Input File Size Limit</h6>
            <p>
                30 MBs.
            </p>

            <h5 className="headings-color"><Button iconName='Note' size='lg'/> Note</h5>
            This project is officially over and the developed framework may not perform perfectly:
            <ul>
                <li>All the tasks are completed automatically in the background, so there is no guarantee of completeness and accuracy.</li>
                <li>
                    If an error happens on the backend it may not inform the frontend properly, leading to a failure.
                </li>
            </ul>

            <h5 className="headings-color"><Button iconName='Developers' size='lg'/> Developers</h5>
            <ul className="mt-2">
                <li>
                    Tobias Backes
                    <span className="ms-2">
                        <a href="https://orcid.org/0000-0003-2492-5297" target="_blank" rel="noopener noreferrer">ORCID</a>
                    </span>
                </li>
                <li className="mt-1">
                    Muhammad Ahsan Shahid
                    <span className="ms-2">
                        <a href="https://orcid.org/0000-0002-7274-7934" target="_blank" rel="noopener noreferrer">ORCID</a> <a href="https://www.linkedin.com/in/muhammad-ahsan-shahid/" target="_blank" rel="noopener noreferrer">LinkedIn</a> <a href="https://github.com/MAhsanShahid" target="_blank" rel="noopener noreferrer">GitHub</a>
                    </span>
                </li>
                <li className="mt-1">
                    Philipp Mayr - Team Lead
                    <span className="ms-2">
                        <a href="https://orcid.org/0000-0002-6656-1658" target="_blank" rel="noopener noreferrer">ORCID</a>
                    </span>
                </li>
            </ul>

            {/*<h5 className="headings-color"><Button iconName='License' size='lg'/> License</h5>*/}
            {/*<p>*/}
            {/*    This work is licenced under GPL-3.0, or later.*/}
            {/*</p>*/}

            <h5 className="headings-color"><Button iconName='Funding' size='lg'/> Funding</h5>
            <p>
                This work was funded by <a href="https://www.dfg.de/en" target="_blank" rel="noopener noreferrer">
                <img src={Dfg} alt="Dfg" style={{height: '80px', maxWidth:'150px', verticalAlign: 'middle'}}/>
                </a>, reference number: 293069437 (MA 3964/8-2; STA 572/14-2).
            </p>
        </div>

        <div className="col-md-11">
            <h2 className="text-center headings-color mt-3">Dissemination & Literature</h2>
            <h4><Button iconName='Workshops' size='lg'/> Workshops</h4>
            <ul>
                <li>
                    In June 2022, the OUTCITE team held an international workshop on “Understanding Literature References in Academic Full Text (ULITE):
                    <cite> Iurshina, A., Shahid, M. A., Backes, T., Mayr, P., & Staab, S. (2022). Understanding Literature References in Academic Full Text (ULITE). JCDL ’22: Proceedings of the 22nd ACM/IEEE Joint Conference on Digital Libraries. https://doi.org/10.1145/3529372.3530942</cite>” in co-location with the JCDL conference.
                    The workshop outcomes are documented on
                    <a href="https://exciteproject.github.io/ULITE-ws/" target="_blank" rel="noopener noreferrer"> this page</a>,
                    workshop proceedings:
                    <a href="https://ceur-ws.org/Vol-3220/" target="_blank" rel="noopener noreferrer"> here</a>.
                </li>
                <li>
                    In October 2022, the team presented online at the
                    <a href="https://workshop-oc.github.io/2022/" target="_blank" rel="noopener noreferrer"> Workshop on Open Citations and Open Scholarly Metadata</a>.
                </li>
                <li>
                    In October 2023, the team presented on-site in Bologna the preliminary results of the project at the
                    <a href="https://zenodo.org/records/10049609" target="_blank" rel="noopener noreferrer"> Workshop on Open Citations & Open Scholarly Metadata 2023</a>.
                    Talk: “Outcomes of the OUTCITE Project on Reference Extraction & Linking in the Social Sciences”.
                </li>
                <li>
                    In April 2024, the team presented on-site in Frankfurt at the peDOCS-Kooperationspartnertreffen.
                    Talk: “Ergebnisse des OUTCITE-Projekts zur Referenzextraktion und -verknüpfung in den Sozialwissenschaften”.
                </li>
                <li>
                    In August 2024, OUTCITE project results and the pipeline were demonstrated at the
                    <a href="https://aclweb.org/anthology/2024/" target="_blank" rel="noopener noreferrer"> Workshop on Scholarly Document Processing</a> in co-location with the ACL conference: <cite>Ghosal et al. (2024). Overview of the Fourth Workshop on Scholarly Document Processing. Proceedings of the Fourth Workshop on Scholarly Document Processing (SDP 2024) (pp. 1–6). Association for Computational Linguistics. https://aclanthology.org/2024.sdp-1.1</cite>.
                </li>
            </ul>

            <h4 className="mt-2"><Button iconName='Publications' size='lg'/> Publications</h4>
            <ul>
                <li>
                    Hosseini, A., Ghavimi, B., Boukhers, Z., & Mayr, P. (2019). <a href="https://doi.org/10.1109/JCDL.2019.00105" target="_blank" rel="noopener noreferrer">EXCITE - A toolchain to extract, match and publish open literature references</a>. Proceedings of the ACM/IEEE Joint Conference on Digital Libraries 2019, 432–433. DOI: <a href="https://doi.org/10.1109/JCDL.2019.00105" target="_blank" rel="noopener noreferrer">https://doi.org/10.1109/JCDL.2019.00105</a>
                </li>
                <li>
                    Backes, T., Iurshina, A., Shahid, M. A., & Mayr, P. (2024).&nbsp;
                    <a href="https://doi.org/10.1007/s00799-024-00404-6" target="_blank" rel="noopener noreferrer">Comparing Free Reference Extraction Pipelines</a>.
                    International Journal on Digital Libraries, 25(4), 841–853. DOI: <a href="https://doi.org/10.1007/s00799-024-00404-6" target="_blank" rel="noopener noreferrer">https://doi.org/10.1007/s00799-024-00404-6</a>; Preprint: <a href="https://zenodo.org/records/11072332" target="_blank" rel="noopener noreferrer">https://zenodo.org/records/11072332</a>
                </li>
                <li>
                    Tobias Backes, Stefan Dietze:
                    <a href="https://dl.acm.org/doi/10.1145/3646553" target="_blank" rel="noopener noreferrer"> Connected Components for Scaling Partial-order Blocking to Billion Entities</a>.
                    ACM J. Data Inf. Qual. 16(1): 9:1-9:29 (2024).
                </li>
                <li>
                    Backes, T. (2023).
                    <a href="https://docserv.uni-duesseldorf.de/servlets/DocumentServlet?id=62212" target="_blank" rel="noopener noreferrer"> Partial Orders and Progressive Blocking: A Matching-based Framework for Large-scale Entity Resolution in Bibliographic Data</a>
                    [PhD Thesis, Heinrich-Heine-Universität, Düsseldorf, Germany].
                </li>
                <li>
                    Backes, T., Hienert, D., & Dietze, S. (2022).
                    <a href="https://doi.org/10.1007/s00799-022-00326-1" target="_blank" rel="noopener noreferrer"> Towards hierarchical affiliation resolution: Framework, baselines, dataset</a>.
                    International Journal on Digital Libraries, 23(3), 267–288.
                </li>
                <li>
                    Backes, T., & Dietze, S. (2022).
                    <a href="https://doi.org/10.1016/j.is.2022.102056" target="_blank" rel="noopener noreferrer"> Lattice-based progressive author disambiguation</a>.
                    Information Systems, 109, 102056.
                </li>
                <li>
                    Christian Boulanger, Anastasiia Iurshina.
                    <a href="https://ceur-ws.org/Vol-3220/paper3.pdf" target="_blank" rel="noopener noreferrer"> Extracting bibliographic references from footnotes with EXcite-docker</a>.
                    ULITE workshop at JCDL 2022: 26-33.
                </li>
                <li>
                    Birkeneder, B., Aufenvenne, P., Haase, C., Mayr, P., & Steinbrink, M. (2022).
                    <a href="http://ceur-ws.org/Vol-3220/paper4.pdf" target="_blank" rel="noopener noreferrer"> Extracting literature references in German Speaking Geography – the GEOcite project</a>.
                    In Proceedings of the Workshop on Understanding Literature references in academic full Text (pp. 34–41).
                </li>
                <li>
                    Rafika Boutalbi, Mira Ait Saada, Anastasiia Iurshina, Steffen Staab, Mohamed Nadif.
                    <a href="https://doi.org/10.1145/3477495.3531834" target="_blank" rel="noopener noreferrer"> Tensor-based Graph Modularity for Text Data Clustering</a>.
                    In: 45th International ACM SIGIR Conference on Research and Development in Information Retrieval (SIGIR-2022). Madrid, Spain, July 11-15, 2022.
                </li>
                <li>
                    Anastasiia Iurshina, Jiaxin Pan, Rafika Boutalbi, and Steffen Staab.
                    (2022b)
                    <a href="https://doi.org/10.1145/3511808.3557659" target="_blank" rel="noopener noreferrer"> NILK: Entity Linking Dataset Targeting NIL-linking Cases</a>.
                    In: Proceedings of the 31st ACM International Conference on Information & Knowledge Management (CIKM '22).
                </li>
                <li>
                    Nadeen Fathallah, Arunav Das, Stefano De Giorgis, Andrea Poltronieri, Peter Haase, and Liubov Kovriguina.
                    <a href="https://2024.eswc-conferences.org/wp-content/uploads/2024/05/77770034.pdf" target="_blank" rel="noopener noreferrer"> NeOn-GPT: A Large Language Model-Powered Pipeline for Ontology Learning</a>.
                    In: Special Track: Large Language Models for Knowledge Engineering at ESWC-2024, May 26-May 30, 2024. Hersonissos, Greece.
                </li>
                <li>
                    Backes, T., Iurshina, A., & Mayr, P. (Eds.). (2022).
                    <a href="http://ceur-ws.org/Vol-3220/" target="_blank" rel="noopener noreferrer"> Proceedings of the Workshop on Understanding Literature references in academic full Text</a>.
                    CEUR-WS.org.
                </li>
                <li>
                    Pagnotta, O. (2023).
                    <a href="https://doi.org/10.5281/zenodo.10036455" target="_blank" rel="noopener noreferrer"> Investigating the performance of GROBID and OUTCITE (Version v1)</a>.
                    Zenodo.
                </li>
                <li>
                    Olga Pagnotta. (2024).
                    <a href="https://doi.org/10.5281/zenodo.10468608" target="_blank" rel="noopener noreferrer"> olgagolgan/RefEx: RefEx project code (scripts)</a>.
                    Zenodo.
                </li>
                <li>
                    Anastasiia Iurshina, Jiaxin Pan, Rafika Boutalbi, Steffen Staab.
                    (2023) Data for:
                    <a href="https://doi.org/10.18419/darus-3454" target="_blank" rel="noopener noreferrer"> NILK, entity linking dataset targeting NIL-linking cases</a>.
                    DaRUS. 321 downloads by August 26, 2024.
                </li>
                <li>
                    Hasan Evci.
                    <a href="https://elib.uni-stuttgart.de/bitstream/11682/11957/1/211221_EVCI%20-%20B.Sc.Inf_BachelorThesis_ExtractingReferencesFromPDFDocumentsWithBERT.pdf" target="_blank" rel="noopener noreferrer"> Extracting and Segmenting High-Variance References from PDF Documents with BERT</a>.
                    Bachelor Thesis at University of Stuttgart.
                </li>
                <li>
                    <strong>Data sets:</strong> NIL entity linking (Iurshina et al. 2022b, Iurshina et al 2023) has been established as a relevant natural language processing task, picked up by other researchers: <cite>E.g., Nicolas Heist, Heiko Paulheim: NASTyLinker: NIL-Aware Scalable Transformer-Based Entity Linker. ESWC 2023: 174-191.</cite>.
                </li>
            </ul>
        </div>
        <div className='col-12'>
            <Footers/>
        </div>
    </div>;
};

export default About;
