import React, {Component} from "react";
import './styles/Header.sass'
import outcite from '../images/oucite.PNG'
import Button from "./Button";

class Header extends Component {
    render() {
        return (

            <div className="row border-btm">
                {this.props.showHomeBtn ?
                    <>
                        {this.props.noInfoBtn?null:<div className="col-1 home-btn">
                            <a className='btn-clr' href='/' rel='noreferrer'><Button iconName='Home' size='2x'/></a>
                        </div>}
                        <div className={this.props.noInfoBtn?"col-11 text-center":"col-10 text-center"}>
                            <img src={outcite} alt="outcite" onClick={()=>window.open("/", "_self")}/>
                            {/*className="margin-left"*/}
                        </div>
                        {<div className="col-1 home-btn text-end">
                            <a className='btn-clr' href={this.props.noInfoBtn?'/':'/about'}
                               rel='noreferrer'><Button iconName={this.props.noInfoBtn?'Home':'Info'} size='2x'/></a>

                        </div>}
                    </>
                    :
                    <>
                        <div className="col-11 text-center">
                            <img src={outcite} alt="outcite" onClick={()=>window.open("/", "_self")}/>
                            {/*className="margin-left"*/}
                        </div>
                        <div className="col-1 home-btn text-end">
                            <a className='btn-clr' href='/about'
                               rel='noreferrer'><Button iconName='Info' size='2x'/></a>

                        </div>
                    </>
                }
            </div>
        )
    }
}

export default Header;