import {Component} from "react";
import './styles/Tiles.sass';
import {faDatabase} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Accordions from './Accordions'
import SearchBar from "./SearchBar";
import Filters from "./Filters";
import cloneDeep from 'lodash/cloneDeep';

class Tiles extends Component {
    constructor(props) {
        super(props);

        this.state = {
            from: 0,
            size: 10,
            result: null,
            loading: false,
            filteredResult: null
        };
        this.getResults = this.getResults.bind(this)
        this.updateResults = this.updateResults.bind(this)
        this.filterResults = this.filterResults.bind(this)
    }


    getResults(index, id, q, from, size) {
        this.setState({
            result: null,
            loading: true,
            filteredResult: null,
        })
        let esAddress = 'https://demo-outcite.gesis.org:443/';
            // http://outcite.gesis.org:80/
            // 'http://193.175.238.110:8003/'
        let url = id ? esAddress + index + '/_search?q=_id:' + id :
            q ? esAddress + index + '/_search?q=fulltext:"' + q + '" OR cermine_fulltext:"' + q + '" OR _id:"' + q + '"' :
                esAddress + index + '/_search?from=' + from + '&size=' + size
        fetch(url)
            .then(response => response.json())
            .then(result => {
                this.setState({
                    result: result,
                    loading: false
                })
            })
            .catch(error => console.log('error', error));
    }

    updateResults(from, size) {
        this.setState({
            from: from,
            size: size
        })
        this.setState({result: null})
        this.getResults(this.props.index, null, null, from, size)

    }

    filterResults(filters) {
        if (filters.length > 0) {
            let resultCopy = cloneDeep(this.state.result);
            for (let i = 0; i < resultCopy['hits']['hits'].length; i++) {
                let _source = {}

                for (const [key, value] of Object.entries(resultCopy['hits']['hits'][i]['_source'])) {
                    for (let j = 0; j < filters.length; j++) {
                        if(Array.isArray(filters[j]['value'])){
                            filters[j]['value'].forEach((f)=>{
                                if (f["value"] === key) {
                                    _source[key] = value
                                }
                            })
                        }
                        else if (key === filters[j]['value']) {
                            _source[key] = value
                        }
                        // if (key === filters[j]['value']) {
                        //     _source[key] = value
                        // }
                    }
                }
                resultCopy['hits']['hits'][i]['_source'] = _source
            }
            this.setState({
                filteredResult: resultCopy
            })
        } else {
            this.setState({filteredResult: null})
        }
    }

    render() {
        return <>
            <div className="col-12 flip-card">
                <div className="flip-card-inner">
                    <div className="flip-card-front">
                        <FontAwesomeIcon className='fa-5x icon' icon={faDatabase}/>
                        <h4><b>{this.props.title}</b></h4>
                    </div>
                    <div className="flip-card-back">
                        <h4 className='margin-top text-color'><b>{this.props.title}</b></h4>
                        <SearchBar getResults={this.getResults} index={this.props.index} class={'search'}
                                   placeholder={'Search by ID'} idSearch/>
                        <button type="button" className="btn btn-danger margin-top"
                                onClick={() => this.getResults(this.props.index, null, null, this.state.from, this.state.size)}>
                            Show Documents
                        </button>
                    </div>
                </div>
            </div>
            {this.state.result ?
                <div className="col-12 text-center margin-top padding">
                    <Filters filterResults={this.filterResults}/>
                </div> : null
            }
            {this.state.result && !this.state.filteredResult ?
                <div className="col-12">
                    <Accordions hits={this.state.result['hits']['hits']} updateResults={this.updateResults}
                                from={this.state.from} size={this.state.size} getIdIndex={this.props.getIdIndex}/>
                </div>
                : this.state.filteredResult ?
                    <div className="col-12">
                        <Accordions hits={this.state.filteredResult['hits']['hits']} updateResults={this.updateResults}
                                    from={this.state.from} size={this.state.size} getIdIndex={this.props.getIdIndex}/>
                    </div>
                    : this.state.loading ?
                        <div className="col-12">
                            <div className="text-center load">
                                <div className="spinner-grow text-danger" role="status">
                                    {/*<span className="sr-only">Loading...</span>*/}
                                </div>
                            </div>
                        </div>
                        :
                        null
            }
        </>;

    }
}

export default Tiles;