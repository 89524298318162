import {Component} from 'react';
import Select from 'react-select';
import {fieldNames} from "../data/fieldNames";
import "./styles/Filters.sass";

let filters = {selectedOption: []}

class Filters extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedOption: null,
        };
    }

    componentDidMount() {

        if (filters['selectedOption'].length > 0) {
            this.props.filterResults(filters['selectedOption'])
        }

    }

    handleChange = (selectedOption) => {
        this.setState({selectedOption}, () => {
            filters['selectedOption'] = this.state.selectedOption
            this.props.filterResults(filters['selectedOption'])
        });

    };

    render() {
        const {selectedOption} = filters['selectedOption'] ? filters : this.state;
        return (
            <Select
                value={selectedOption}
                onChange={this.handleChange}
                options={fieldNames}
                // options={fieldNames.sort((a, b) => (a.label > b.label) ? 1 : -1)}
                isMulti
                closeMenuOnSelect={false}
                hideSelectedOptions={true}
                allowSelectAll={true}
                // isSearchable
                placeholder={"filter fields to display..."}
            />
        );
    }
}

export default Filters;
