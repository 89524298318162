import React, {useState, useEffect} from "react";
import './styles/Results.sass';
import Accordions from "./Accordions";
import {useParams} from "react-router-dom";
import Filters from "./Filters";
import cloneDeep from "lodash/cloneDeep";
import Footers from "./Footers";


function Results() {
    // We can use the `useParams` hook here to access
    // the dynamic pieces of the URL.
    let {index, id, includes_field} = useParams();

    const [results, setResults] = useState({});
    const [filteredResults, setFilteredResults] = useState(null);
    const [flag, setFlag] = useState(true);
    const [from, setFrom] = useState(0);
    const [size, setSize] = useState(10);
    const [selectedFilters, setSelectedFilters] = useState(null);
    const [loading, setLoading] = useState(false);

    const getResults = () => {
        setLoading(true)
        let esAddress = 'https://demo-outcite.gesis.org:443/';
        // http://outcite.gesis.org:80/
            // 'http://193.175.238.110:8003/'
        let url = includes_field && id ? esAddress + index + '/_doc/' + id + '?_source_includes=' + includes_field
            :
            index && id ? esAddress + index + '/_doc/' + id
                :
                index && !id ? esAddress + index + '/_search?from=' + from + '&size=' + size
                    :
                    null
        // if(index==='users'){
        //     setTimeout(() => {
        //         fetch(url)
        //             .then(response => response.json())
        //             .then(result => {
        //                 setResults(result)
        //                 setLoading(false)
        //             })
        //             .catch(error => console.log('error', error));
        //     }, "30000")
        // }
        // else{
            fetch(url)
                .then(response => response.json())
                .then(result => {
                    setResults(result)
                    setLoading(false)
                })
                .catch(error => console.log('error', error));
        // }

    }
    if (flag) {
        getResults()
        setFlag(false)
    }

    const filterResults = (filters) => {
        if (filters.length > 0) {
            let resultCopy = cloneDeep(results);
            if ('hits' in resultCopy && 'hits' in resultCopy['hits']) {
                for (let i = 0; i < resultCopy['hits']['hits'].length; i++) {
                    let _source = {};
                    for (const [key, value] of Object.entries(resultCopy['hits']['hits'][i]['_source'])) {
                        for (let j = 0; j < filters.length; j++) {
                            if(Array.isArray(filters[j]['value'])){
                                filters[j]['value'].forEach((f)=>{
                                    if (f["value"] === key) {
                                        _source[key] = value
                                    }
                                })
                            }
                            else if (key === filters[j]['value']) {
                                _source[key] = value
                            }
                            // if (key === filters[j]['value']) {
                            //     _source[key] = value
                            // }
                        }
                    }
                    resultCopy['hits']['hits'][i]['_source'] = _source
                }
            } else {
                let _source = {}
                for (const [key, value] of Object.entries(resultCopy['_source'])) {
                    for (let j = 0; j < filters.length; j++) {
                        if(Array.isArray(filters[j]['value'])){
                            filters[j]['value'].forEach((f)=>{
                                if (f["value"] === key) {
                                    _source[key] = value
                                }
                            })
                        }
                        else if (key === filters[j]['value']) {
                            _source[key] = value
                        }
                        // if (key === filters[j]['value']) {
                        //     _source[key] = value
                        // }
                    }
                }
                resultCopy['_source'] = _source
            }
            setFilteredResults(resultCopy)
            setSelectedFilters(filters)
        } else {
            setFilteredResults(null)
            setSelectedFilters(null)
        }
    }

    const updateResults = (newFrom) => {
        // let newFrom = from + size
        setFrom(newFrom)
    }

    useEffect(() => {
        // action on update of from
        setResults({})
        getResults()
    }, [from]);

    useEffect(() => {
        // action on update of results
        if (results && !(Object.keys(results).length === 0) && selectedFilters) {
            filterResults(selectedFilters)
        }
    }, [results]);


    return (
        <div className='row'>
            {
                !includes_field ?
                    <>
                        <div className="col-12 text-center margin-top padding">
                            <Filters filterResults={filterResults}/>
                        </div>
                        {loading ? <div className="col-12 text-center load">
                            <div className="spinner-grow text-danger" role="status">
                                {/*<span className="sr-only">Loading...</span>*/}
                            </div>
                        </div> : null}
                    </>
                    :
                    null
            }

            {results && !filteredResults ?
                <div className="col-12">
                    {'_id' in results && '_source' in results ?
                        <Accordions uuid={id} results hits={[results]}/>
                        :
                        'hits' in results && 'hits' in results['hits'] ?
                            <>
                                <Accordions uuid={id} results hits={results['hits']['hits']}/>
                                <button type="button" className="btn btn-success margin-top right-button"
                                        onClick={() => updateResults(from + size)}> Next &raquo; </button>
                                <button type="button" className="btn btn-danger margin-top" disabled={from === 0}
                                        onClick={() => from >= size ? updateResults(from - size) : null}> &laquo; Back
                                </button>
                            </>
                            :
                            null
                    }
                </div>
                :
                filteredResults ?
                    <div className="col-12">
                        {'_id' in filteredResults && '_source' in filteredResults ?
                            <Accordions uuid={id} results hits={[filteredResults]}/>
                            :
                            'hits' in filteredResults && 'hits' in filteredResults['hits'] ?
                                <>
                                    <Accordions uuid={id} results hits={filteredResults['hits']['hits']}/>
                                    <button type="button" className="btn btn-success margin-top right-button"
                                            onClick={() => updateResults(from + size)}> Next &raquo; </button>
                                    <button type="button" className="btn btn-danger margin-top" disabled={from === 0}
                                            onClick={() => from >= size ? updateResults(from - size) : null}> &laquo; Back
                                    </button>
                                </>
                                :
                                null
                        }

                    </div>
                    :
                    null
            }
            <div className='col-12'>
            <Footers/>
            </div>
        </div>
    );
}


export default Results;